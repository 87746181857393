<template>
  <div>
    <header class="el-drawer__header nanw_khan">
      <span title="Новый заказ">{{ $t("message.new_product") }}</span>
      <el-button
        type="success"
        size="small"
        class="mr-1"
        @click="submitForm()"
        >{{ $t("message.save") }}</el-button
      >
      <el-button
        type="primary"
        size="small"
        class="mr-1"
        @click="submitForm(true)"
        >{{ $t("message.save_and_create_new") }}</el-button
      >
      <el-button
        type="warning"
        icon="el-icon-close"
        size="small"
        @click="close()"
        >{{ $t("message.close") }}</el-button
      >
    </header>
    <div class="body__modal">
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        size="small"
        class="aticler_m stylekhanForm"
      >
        <el-row :gutter="40">
          <el-col :span="24">
            <el-form-item :label="columns.name.title" prop="name">
              <el-input
                v-model="form.name"
                :placeholder="columns.name.title"
              ></el-input>
            </el-form-item>
          </el-col>
          <!-- end  col -->
          <el-col :span="8">
            <el-form-item :label="columns.code.title" prop="code">
              <el-input
                v-model="form.code"
                :placeholder="columns.code.title"
              ></el-input>
            </el-form-item>
            <!-- end el-form-item-->

            <el-form-item :label="columns.price.title" prop="price">
              <el-row :gutter="5">
                <el-col :sm="12">
                  <el-form-item prop="price">
                    <el-input
                      v-model="form.price"
                      :placeholder="columns.price.title"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :sm="12">
                  <el-form-item class="mb-0" prop="currency_id">
                    <el-select
                      v-model="form.currency_id"
                      :placeholder="columns.currency.title"
                      filterable
                      clearable
                    >
                      <el-option
                        v-for="item in currencies"
                        :key="item.id + item.name"
                        :label="item.name"
                        :value="item.id"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form-item>
            <!-- end el-form-item-->

            <!-- <el-form-item label="Цена продажи">
              <el-row :gutter="5">
                <el-col :sm="12">
                  <el-form-item class="mb-0">
                    <el-input v-model="form.test5" placeholder="0,00"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :sm="12">
                  <el-form-item class="mb-0">
                    <el-select v-model="form.test6" placeholder="доллар (USD)">
                      <el-option label="Zone one" value="shanghai"></el-option>
                      <el-option label="Zone two" value="beijing"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form-item>-->
            <!-- end el-form-item-->
            <el-row :gutter="5">
              <el-col :sm="12">
                <el-form-item :label="columns.quantity.title" prop="quantity">
                  <el-input
                    v-model="form.quantity"
                    :placeholder="columns.quantity.title"
                  ></el-input>
                </el-form-item>
              </el-col>

              <el-col :span="10">
                <el-form-item
                  :label="columns.measurement.title"
                  prop="measurement_id"
                  class="mb-0"
                >
                  <el-select
                    v-model="form.measurement_id"
                    :placeholder="columns.measurement.title"
                    filterable
                    clearable
                  >
                    <el-option
                      v-for="item in measurements"
                      :key="item.id + item.name"
                      :label="item.name"
                      :value="item.id"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="2" class="mb-0 mt-3">
                <div class="plusic" @click="drawer.measurement.status = true">
                  <i class="el-icon-plus"></i>
                </div>
              </el-col>
            </el-row>
            <!-- end el-form-item-->
            <!-- secondary_measurements -->
            <el-form-item>
              <el-row :gutter="5">
                <template>
                  <div
                    class="mb-5"
                    v-for="(item, index) in form.secondary_measurements"
                    :key="item.measurement_id"
                  >
                    <el-col :span="11">
                      <el-select
                        v-model="
                          form.secondary_measurements[index].measurement_id
                        "
                        :placeholder="columns.measurement.title"
                        filterable
                        clearable
                      >
                        <el-option
                          v-for="s_item in measurements"
                          :key="s_item.id + s_item.name"
                          :label="s_item.name"
                          :value="s_item.id"
                          :disabled="isInList(s_item.id)"
                        ></el-option>
                      </el-select>
                    </el-col>
                    <el-col :span="11">
                      <el-form-item class="mb-0">
                        <el-input
                          v-model="item.rate"
                          placeholder="1 км в"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="2">
                      <el-button
                        type="danger"
                        icon="el-icon-delete"
                        circle
                        @click="removeMeasurement(index)"
                      ></el-button>
                    </el-col>
                  </div>
                </template>
              </el-row>
              <!-- <div class="add_kmv_block" @click="addMeasurement()">
                {{ $t("message.add_unit_of_measure") }}
              </div> -->
            </el-form-item>
            <!-- end el-form-item-->
          </el-col>
          <!-- end  col -->

          <el-col :span="8">
            <el-form-item :label="columns.category.title" prop="category_id">
              <el-row :gutter="5">
                <el-col :span="22">
                  <el-form-item class="mb-0">
                    <el-select
                      v-model="form.category_id"
                      :placeholder="columns.category.title"
                      filterable
                      clearable
                    >
                      <el-option
                        v-for="item in categories"
                        :key="item.id + item.name"
                        :label="item.name"
                        :value="item.id"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="2">
                  <div class="plusic" @click="drawer.group.status = true">
                    <i class="el-icon-plus"></i>
                  </div>
                </el-col>
              </el-row>
            </el-form-item>
            <!-- end el-form-item-->

            <!-- <el-form-item :label="columns.vat.title" prop="vat">
              <el-input
                v-model="form.vat"
                :placeholder="columns.vat.title"
              ></el-input>
            </el-form-item>

            <el-form-item :label="columns.sku.title" prop="sku">
              <el-input
                v-model="form.sku"
                :placeholder="columns.sku.title"
              ></el-input>
            </el-form-item> -->
            <!-- end el-form-item-->
          </el-col>
          <!-- end  col -->

          <el-col :span="8">
            <el-form-item :label="columns.description.title">
              <el-input type="textarea" v-model="form.description"></el-input>
            </el-form-item>

            <!-- end el-form-item-->
          </el-col>
          <!-- end  col -->
        </el-row>
      </el-form>
    </div>

    <el-drawer
      size="50%"
      :wrapperClosable="false"
      :visible.sync="drawer.group.status"
      :with-header="false"
      :ref="drawer.group.name"
      :append-to-body="true"
      :before-close="beforeClose"
    >
      <Group :drawer-name="drawer.group.name" />
    </el-drawer>

    <el-drawer
      size="50%"
      :wrapperClosable="false"
      :visible.sync="drawer.measurement.status"
      :with-header="false"
      :ref="drawer.measurement.name"
      :append-to-body="true"
      :before-close="beforeClose"
    >
      <Measurement :drawer-name="drawer.measurement.name" />
    </el-drawer>
  </div>
</template>
<script>
import Group from "@/views/groupsWarehouses/components/crm-create";
import Measurement from "@/views/measurement/components/crm-create";

import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import drawerChild from "@/utils/mixins/drawer-child";
import form from "@/utils/mixins/form";
import { correctInputNumber } from "@/filters";

export default {
  components: { Group, Measurement },
  mixins: [form, drawerChild],
  data() {
    return {
      tableID: false,
      newGroup: false,
      pagesClicl: false,
      drawer: {
        group: {
          name: "group",
          status: false,
        },
        measurement: {
          name: "measurement",
          status: false,
        },
      },
    };
  },
  async mounted() {
    if (this.currencies && this.currencies.length === 0)
      await this.loadCurrencies().then((res) => {
        if (res.data.currencies.length)
          this.form.currency_id = res.data.currencies[0].id;
      });
    else this.form.currency_id = this.currencies[0].id;
    if (this.categories && this.categories.length === 0)
      await this.loadCategories();
    if (this.measurements && this.measurements.length === 0)
      await this.loadMeasurements();
  },
  computed: {
    ...mapGetters({
      model: "products/model",
      rules: "products/rules",
      money: "noSuffixMoney",
      columns: "products/columns",
      currencies: "currencies/inventory",
      categories: "categories/inventory",
      measurements: "measurements/inventory",
    }),
  },
  created() {
    this.form.price = this.formatInputValue(this.form.price);
    this.form.quantity = this.formatInputValue(this.form.quantity);
  },
  watch: {
    'form.price': function(newPrice) {
      this.correctInputNumber('form', 'price', newPrice);
    },
    'form.quantity': function(newPrice) {
      this.correctInputNumber('form', 'quantity', newPrice);
    },
  },
  methods: {
    isInList(id) {
      const tmp = this.form.secondary_measurements.findIndex((e) => {
        if (e.measurement_id == id) return true;
        return false;
      });
      return tmp == -1 ? false : true;
    },
    async beforeClose(done) {
      // await this.loadCategories();
      await this.loadMeasurements();
      done();
    },
    ...mapActions({
      save: "products/store",
      update: "products/update",
      editModel: "products/show",
      empty: "products/empty",
      loadCurrencies: "currencies/inventory",
      loadCategories: "categories/inventory",
      loadMeasurements: "measurements/inventory",
    }),
    correctInputNumber,
    addMeasurement() {
      if (
        this.form.secondary_measurements.length != 0 &&
        this.form.secondary_measurements[
          this.form.secondary_measurements.length - 1
        ].measurement_id == ""
      )
        return;
      this.form.secondary_measurements.push({
        measurement_id: "",
        rate: "",
      });
    },
    closeModal(drawer) {
      this.$refs[this.drawer[drawer].name].closeDrawer();
    },
    removeMeasurement(index) {
      this.form.secondary_measurements.splice(index, 1);
    },
    listChanged() {
      this.loadCategories();
      // this.reloadList = true;
    },
    submitForm(resetForm = false) {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.form.price = this.strToInt(this.form.price);
          this.form.quantity = this.strToInt(this.form.quantity);
          this.loadingButton = true;
          if (this.form.id == null) {
            this.save(this.form)
              .then((res) => {
                this.loadingButton = false;
                this.form = res.data.product;
                this.$notify({
                  title: "Успешно",
                  type: "success",
                  offset: 130,
                  message: res.message,
                });
              })
              .catch((err) => {
                this.$notify({
                  title: "Ошибка",
                  type: "error",
                  offset: 130,
                  message: err.error.message,
                });
                this.loadingButton = false;
              })
              .finally(() => {
                if (resetForm) {
                  this.$refs["form"].resetFields();
                  this.empty();
                }
              });
          } else {
            this.form.secondary_measurements.forEach((element) => {
              if (element.id) {
                this.form.old_secondary_measurements.push({
                  id: element.id,
                  measurement_id: element.measurement_id,
                  rate: element.rate,
                });
              } else {
                this.form.new_secondary_measurements.push({
                  measurement_id: element.measurement_id,
                  rate: element.rate,
                });
              }
            });
            this.update(this.form)
              .then((res) => {
                this.loadingButton = false;
                this.$notify({
                  title: "Успешно",
                  type: "success",
                  offset: 130,
                  message: res.message,
                });
              })
              .catch((err) => {
                this.loadingButton = false;
                this.$notify({
                  title: "Ошибка",
                  type: "error",
                  offset: 130,
                  message: err.error.message,
                });
              })
              .finally(() => {
                if (resetForm) {
                  this.$refs["form"].resetFields();
                  this.empty();
                }
              });
          }
        }
      });
    },
    formatInputValue(value) {
      // Форматируем значение с разделителями тысяч
      let unformattedValue = value.toString().replace(/\s/g, '');
      return unformattedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    },
    strToInt(val) {
      if (typeof(val) === 'string') {
        return parseFloat(val.replace(/\s/g, ''));
      }
      return val;
    }
  },
};
</script>
